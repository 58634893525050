import { Pipe, PipeTransform } from '@angular/core';
import { isValid } from 'date-fns';

import { LocaleService } from '../+authenticated/shared/locale/locale.service';
import { IntlFormatType } from '../enums';
import { parseDate } from '../shared/date.helper';

interface DateOptions {
  dateStyle?: string;
  timeStyle?: string;
  year?: string;
  month?: string;
  day?: string;
  weekday?: string;
  hour?: string;
  minute?: string;
  second?: string;
  timeZone?: string;
}
@Pipe({
  name: 'localeDate',
  pure: true,
  standalone: true,
})
export class LocaleDatePipe implements PipeTransform {
  public constructor(private localeService: LocaleService) {}

  public transform(value: string | Date | string[] | Date[], options: DateOptions = IntlFormatType.DATE): string {
    if (!value) {
      return '';
    }

    value = Array.isArray(value) ? value.map((date) => parseDate(date)) : parseDate(value);

    if ((Array.isArray(value) && value.some((date) => !isValid(date))) || (!Array.isArray(value) && !isValid(value))) {
      return '';
    }

    const dateTimeFormat = this.localeService.getCurrentDateTimeFormat(options);

    return Array.isArray(value) ? dateTimeFormat.formatRange(value[0], value[1]) : dateTimeFormat.format(value);
  }
}
