import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LocaleService {
  public constructor(private translate: TranslateService) {}

  public getCurrentDateTimeFormat(options = {}): Intl.DateTimeFormat {
    return new Intl.DateTimeFormat(this.translate.currentLang, options);
  }
}
